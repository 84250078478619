import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-visa-card-display',
  templateUrl: './visa-card-display.component.html',
  styleUrls: ['./visa-card-display.component.scss']
})
export class VisaCardDisplayComponent {
  @Input() cardNumber: string = 'XXXX XXXX XXXX XXXX'
  @Input() expirationDate: string = 'MM/YY'
  @Input() cardName: string = 'NOMBRE Y APELLIDO'
  @Input() cvv: string = 'XXX'
}
