import { Component } from '@angular/core';

@Component({
  selector: 'app-kushki',
  templateUrl: './kushki.component.html',
  styleUrls: ['./kushki.component.scss']
})
export class KushkiComponent {

}
