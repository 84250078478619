<div class="container">
  <div class="img-loading">
    <img src="../../../../../assets/svg/success-blue.svg" alt="">
  </div>
  <div class="info">
    <h2 class="message">Pago Procesado</h2>
    <p class="sub-message">
      ¡Tu pago ha sido procesado
      de manera exitosa!
    </p>
  </div>
  <div class="button">
    <ui-button *ngIf="backUrl.length > 0" [full_width]="true" [bg_white]="true" (onClick)="back()">
      <div class="button-content">
        <span>Regresar al comercio</span>
      </div>
    </ui-button>
  </div>
</div>
