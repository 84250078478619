<div class="container">
  <div class="img-loading">
    <img src="../../../../../assets/svg/bad-error.svg" alt="">
  </div>
  <div class="info">
    <h2 class="message">¡Pago Expirado!</h2>
    <p class="sub-message">
      El tiempo para realizar el pago ha expirado.
    </p>
  </div>
  <div class="button">
    <ui-button [full_width]="true" [bg_white]="true" (onClick)="back()">
      <div class="button-content">
        <span>Regresar al comercio</span>
        <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19" fill="none">
          <path
            d="M13.3534 0.81716C13.3416 0.809349 13.3319 0.799003 13.319 0.793302C12.1831 0.295233 10.9298 0.0131562 9.61149 0.000276926C9.60769 0.000276926 9.60389 0.000699167 9.60009 0.000699167C9.59819 0.000699167 9.59608 0.000276926 9.59439 0.000276926C9.38558 -0.00162329 9.17909 0.00639984 8.97408 0.0199125C8.83051 0.0281468 8.68799 0.0418706 8.54653 0.0562278C8.52373 0.0585503 8.50156 0.059606 8.47876 0.0619285V0.0638288C6.81587 0.245828 5.2784 0.851786 3.98605 1.78226L3.05812 0.413258C3.00238 0.332815 2.90569 0.294178 2.81089 0.314236C2.7163 0.334293 2.64367 0.409035 2.62657 0.50468L2.04975 3.68565L1.64395 5.93002C1.63044 6.00244 1.6505 6.07507 1.69927 6.12997C1.74445 6.18127 1.80864 6.2102 1.87557 6.21083C1.88021 6.21083 1.88591 6.21041 1.89077 6.21041L4.41276 6.07655L7.25334 5.92537C7.34899 5.92031 7.43112 5.85929 7.46342 5.76913C7.49678 5.67961 7.47292 5.57932 7.40346 5.5145L6.23103 4.40921C6.96493 3.93268 7.80799 3.6126 8.71291 3.49204L8.71481 3.51885C8.9916 3.48359 9.27304 3.45994 9.55997 3.4629C12.9263 3.49647 15.6273 6.25221 15.594 9.61792C15.5604 12.9843 12.8049 15.6857 9.43857 15.6524C6.07268 15.619 3.3706 12.8631 3.40438 9.49673C3.40755 9.20283 3.43901 8.91632 3.48166 8.63424L0.234426 7.40839C0.0900113 8.05193 0.00703594 8.71891 0.00049081 9.4051C-0.0522925 14.652 4.15897 18.9472 9.405 18.9995C14.6517 19.0517 18.947 14.841 18.9995 9.59428C19.038 5.68658 16.7102 2.30947 13.3534 0.81716Z"
            fill="#395AE8" />
        </svg>
      </div>
    </ui-button>
  </div>
</div>
