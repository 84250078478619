import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ICustomSelectModel } from 'src/app/interfaces';

import * as moment from 'moment';

@Component({
  selector: 'ui-select-datepicker',
  templateUrl: './select-datepicker.component.html',
  styleUrls: ['./select-datepicker.component.scss']
})
export class SelectDatepickerComponent implements OnInit {
  @Input() month: FormControl = new FormControl('')
  @Input() year: FormControl = new FormControl('')

  years: ICustomSelectModel[] = this.getYears()
  months: ICustomSelectModel[] = this.getMonths()

  ngOnInit(): void {
    this.year.valueChanges.subscribe((result) => {
      this.updateMonthsBasedOnYear(result)
    })
  }

  updateMonthsBasedOnYear(selectedYearShort: string): void {
    const currentYear = moment().year();
    const selectedYear = moment(selectedYearShort, 'YY').year();

    if (selectedYear === currentYear) {
      const currentMonth = moment().month() + 1;
      this.months = months.filter(m => parseInt(m.value as string) >= currentMonth);
      this.month.setValue('')
    } else {
      this.months = months;
    }
  }

  getYears(): ICustomSelectModel[] {
    return this.getFilteredYears()
  }

  getMonths(): ICustomSelectModel[] {
    return months
  }

  getFilteredYears(): ICustomSelectModel[] {
    const currentYear = moment().format('YY').toString()
    return years.filter(y => parseInt(y.value as string) >= +currentYear);
  }
}

const years: ICustomSelectModel[] = [
  { label: '22', value: '22' },
  { label: '23', value: '23' },
  { label: '24', value: '24' },
  { label: '25', value: '25' },
  { label: '26', value: '26' },
  { label: '27', value: '27' },
  { label: '28', value: '28' },
  { label: '29', value: '29' },
  { label: '30', value: '30' },
  { label: '31', value: '31' },
  { label: '32', value: '32' },
  { label: '33', value: '33' },
  { label: '34', value: '34' },
  { label: '35', value: '35' },
  { label: '36', value: '36' },
  { label: '37', value: '37' },
  { label: '38', value: '38' },
  { label: '39', value: '39' },
  { label: '40', value: '40' },
  { label: '41', value: '41' },
  { label: '42', value: '42' },
  { label: '43', value: '43' },
  { label: '44', value: '44' },
  { label: '45', value: '45' },
  { label: '46', value: '46' },
  { label: '47', value: '47' },
  { label: '48', value: '48' },
  { label: '49', value: '49' },
  { label: '50', value: '50' }
];

const months: ICustomSelectModel[] = [
  { label: '1', value: '01' },  // Enero
  { label: '2', value: '02' },  // Febrero
  { label: '3', value: '03' },  // Marzo
  { label: '4', value: '04' },  // Abril
  { label: '5', value: '05' },  // Mayo
  { label: '6', value: '06' },  // Junio
  { label: '7', value: '07' },  // Julio
  { label: '8', value: '08' },  // Agosto
  { label: '9', value: '09' },  // Septiembre
  { label: '10', value: '10' }, // Octubre
  { label: '11', value: '11' }, // Noviembre
  { label: '12', value: '12' }  // Diciembre
];
