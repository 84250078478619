import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-card-back-display',
  templateUrl: './card-back-display.component.html',
  styleUrls: ['./card-back-display.component.scss']
})
export class CardBackDisplayComponent {
  @Input() cvv: string = 'XXX'
  @Input() background: string = ''
}
