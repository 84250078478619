<ui-spinner
  [diameter]="diameter"
  [spinnerButton]="spinnerButton"
  [color]="color"
  [mode]="mode"
  [value]="value"
>
  <div slot="image">
    <img class="img" src="../../../../../assets/images/favicon.ico" alt="" />
  </div>
</ui-spinner>
