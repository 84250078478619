import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-default-card-display',
  templateUrl: './default-card-display.component.html',
  styleUrls: ['./default-card-display.component.scss']
})
export class DefaultCardDisplayComponent {
  @Input() cardNumber: string = 'XXXX XXXX XXXX XXXX'
  @Input() expirationDate: string = 'MM/YY'
  @Input() cardName: string = 'NOMBRE Y APELLIDO'
  @Input() cvv: string = 'XXX'
}
