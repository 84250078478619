<div class="cards">
  <ui-card-front-display>
    <div class="default-card-front">
      <div class="card-chip">
        <img class="arrow" src="../../../../../assets/svg/arrow_left.svg" alt="">
        <img class="card-chip-img" src="../../../../../assets/svg/credit-card-chip.svg" alt="">
      </div>
      <div class="card-data">
        <p>{{cardNumber}}</p>
        <div class="card-data-info">
          <p style="overflow: hidden;">{{cardName.length > 0 ? cardName : 'NOMBRE Y APELLIDO'}}</p>
          <p>{{expirationDate.length > 1 ? expirationDate : 'MM/YY'}}</p>
        </div>
      </div>
    </div>
  </ui-card-front-display>
  <ui-card-back-display [cvv]="cvv">
    <div class="default-card-back">
      <div class="master-card-back">
        <div class="card-chip"></div>
        <div class="card-data">
          <div class="card-data-info"></div>
          <div class="cvv">
            <p>{{cvv}}</p>
          </div>
        </div>
      </div>
    </div>
  </ui-card-back-display>
</div>
