<div class="cards">
  <ui-card-front-display [background]="'bg-dinners'">
    <div class="diners-card-front">
      <div class="card-info">
        <img class="arrow" src="../../../../../assets/svg/diners-logo.svg" alt="">
        <div class="card-chip">
          <img class="arrow" src="../../../../../assets/svg/arrow_left_black.svg" alt="">
          <img class="card-chip-img" src="../../../../../assets/svg/credit-card-chip-black.svg" alt="">
        </div>
      </div>
      <div class="card-data">
        <p>{{cardNumber}}</p>
        <div class="card-data-info">
          <p style="overflow: hidden;">{{cardName.length > 0 ? cardName : 'NOMBRE Y APELLIDO'}}</p>
          <p>{{expirationDate.length > 1 ? expirationDate : 'MM/YY'}}</p>
        </div>
      </div>
    </div>
  </ui-card-front-display>
  <ui-card-back-display [cvv]="cvv" [background]="'bg-dinners'">
    <div class="diners-card-back">
      <div class="card-chip"></div>
      <div class="card-data">
        <div class="card-data-info"></div>
        <div class="cvv">
          <p>{{cvv}}</p>
        </div>
      </div>
    </div>
  </ui-card-back-display>
</div>
