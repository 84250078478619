import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-in-progress-screen',
  templateUrl: './payment-in-progress-screen.component.html',
  styleUrls: ['./payment-in-progress-screen.component.scss']
})
export class PaymentInProgressScreenComponent {

}
