import { Component, Input } from '@angular/core';

@Component({
  selector: 'ui-card-front-display',
  templateUrl: './card-front-display.component.html',
  styleUrls: ['./card-front-display.component.scss']
})
export class CardFrontDisplayComponent {
  @Input() background: string = ''
}
