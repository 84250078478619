import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoadingScreenComponent } from './components/loading-screen/loading-screen.component';
import { SuccessScreenComponent } from './components/success-screen/success-screen.component';
import { ErrorScreenComponent } from './components/error-screen/error-screen.component';
import { UiModule } from '../ui/ui.module';
import { MatIconModule } from '@angular/material/icon';
import { BadErrorScreenComponent } from './components/bad-error-screen/bad-error-screen.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { MatCardModule } from '@angular/material/card';
import { PaymentExpiredScreenComponent } from './components/payment-expired-screen/payment-expired-screen.component';
import { DefaultCardDisplayComponent } from './components/default-card-display/default-card-display.component';
import { MasterCardDisplayComponent } from './components/master-card-dislay/master-card-display.component';
import { VisaCardDisplayComponent } from './components/visa-card-display/visa-card-display.component';
import { AmexCardDisplayComponent } from './components/amex-card-display/amex-card-display.component';
import { DinersCardDisplayComponent } from './components/diners-card-display/diners-card-display.component';
import { DynamicCardViewerComponent } from './components/dynamic-card-viewer/dynamic-card-viewer.component';
import { PaymentInProgressScreenComponent } from './components/payment-in-progress-screen/payment-in-progress-screen.component';

@NgModule({
  declarations: [
    LoadingScreenComponent,
    SuccessScreenComponent,
    ErrorScreenComponent,
    BadErrorScreenComponent,
    NotFoundComponent,
    PaymentExpiredScreenComponent,
    MasterCardDisplayComponent,
    DefaultCardDisplayComponent,
    VisaCardDisplayComponent,
    AmexCardDisplayComponent,
    DinersCardDisplayComponent,
    DynamicCardViewerComponent,
    PaymentInProgressScreenComponent,
  ],
  imports: [
    CommonModule,
    UiModule,
    MatIconModule,
    MatCardModule,
  ],
  exports: [
    LoadingScreenComponent,
    SuccessScreenComponent,
    ErrorScreenComponent,
    BadErrorScreenComponent,
    NotFoundComponent,
    PaymentExpiredScreenComponent,
    DynamicCardViewerComponent,
    PaymentInProgressScreenComponent
  ]
})
export class SharedModule { }
