<div class="container-box">
  <mat-card>
    <div class="flex">
      <div class="item-1">
        <ui-page-template title="TARJETA DE CREDITO/DEBITO">
          <mat-card-content slot="content">
            <div class="container">
              <router-outlet></router-outlet>
            </div>
          </mat-card-content>
        </ui-page-template>
      </div>
      <div class="item-2">
        <div class="powered-by">
          <span> Powered by </span>
          <span class="logotipe">
            <img src="../../../../../assets/svg/monnet.svg" alt="" />
          </span>
        </div>
      </div>
      <div class="item-3">
        <mat-card-actions align="end">
          <span>
            <img src="../../../../../assets/svg/kushki.svg" alt="" />
          </span>
        </mat-card-actions>
      </div>
    </div>
  </mat-card>
</div>
