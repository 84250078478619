import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-rejected',
  templateUrl: './payment-rejected.component.html',
  styleUrls: ['./payment-rejected.component.scss']
})
export class PaymentRejectedComponent {

}
