import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-amex-card-display',
  templateUrl: './amex-card-display.component.html',
  styleUrls: ['./amex-card-display.component.scss']
})
export class AmexCardDisplayComponent {
  @Input() cardNumber: string = 'XXXX XXXX XXXX XXXX'
  @Input() expirationDate: string = 'MM/YY'
  @Input() cardName: string = 'NOMBRE Y APELLIDO'
  @Input() cvv: string = 'XXX'
}
