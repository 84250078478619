import { Component } from '@angular/core';

@Component({
  selector: 'app-payment-in-progress',
  templateUrl: './payment-in-progress.component.html',
  styleUrls: ['./payment-in-progress.component.scss']
})
export class PaymentInProgressComponent {

}
