<form [formGroup]="form">
  <div class="datepicker-select">
    <ui-input-label [color]="labelColor" [label]="startDateLabel" [required]="startDateRequired"></ui-input-label>
    <mat-form-field class="datepicker" [ngClass]="max_width ? 'custom-width select' : 'full-width'">
      <!-- <mat-label>{{ startDateLabel }}</mat-label> -->
      <input matInput class="custom-input-heigth" [min]="minDate" [formControl]="startDate"
        [placeholder]="startDayPlaceholder" [matDatepicker]="sdate" (dateInput)="onStartDateChange($event)" />
      <mat-datepicker-toggle matSuffix [for]="sdate"></mat-datepicker-toggle>
      <mat-datepicker #sdate startView="multi-year" (monthSelected)="setMonthAndYear($event, sdate)"
        panelClass="example-month-picker">
      </mat-datepicker>
    </mat-form-field>
  </div>
  <div class="datepicker-select" *ngIf="isRange">
    <ui-input-label [color]="labelColor" [label]="endDateLabel" [required]="endDateRequired"></ui-input-label>
    <mat-form-field *ngIf="isRange" class="datepicker" [ngClass]="max_width ? 'custom-width select' : 'full-width'">
      <!-- <mat-label>{{ endDateLabel }}</mat-label> -->
      <input matInput class="custom-input-heigth" [formControl]="endDate" [placeholder]="endDayPlaceHolder"
        [matDatepicker]="edate" (dateInput)="onEndDateChange($event)" />
      <mat-datepicker-toggle matSuffix [for]="edate"></mat-datepicker-toggle>
      <mat-datepicker #edate></mat-datepicker>
    </mat-form-field>
  </div>
</form>

<!-- placeholder="{{ startDateLabel | translate }}" -->
<!-- placeholder="{{ endDateLabel | translate }}" -->
