import { Component } from '@angular/core';

@Component({
  selector: 'app-pci-proxy-page',
  templateUrl: './pci-proxy-page.component.html',
  styleUrls: ['./pci-proxy-page.component.scss']
})
export class PciProxyPageComponent {

  constructor(
  ) {}

}
