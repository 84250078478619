import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-success-screen',
  templateUrl: './success-screen.component.html',
  styleUrls: ['./success-screen.component.scss']
})
export class SuccessScreenComponent {
  @Input() backUrl: string = ''

  back(): void {
    window.location.replace(this.backUrl)
  }
}
