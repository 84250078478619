import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';

import moment, { Moment } from 'moment';

export const MY_FORMATS = {
  parse: {
    dateInput: 'MM/YY',
  },
  display: {
    dateInput: 'MM/YY',
    monthYearLabel: 'MMM YY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YY',
  },
};

@Component({
  selector: 'ui-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ]
})


export class DatepickerComponent implements OnInit {
  @Input() startDateLabel: string = ''
  @Input() endDateLabel: string = ''
  @Input() startDayPlaceholder: string = ''
  @Input() endDayPlaceHolder: string = ''
  @Input() labelColor: string = 'white'

  @Input() isRange: boolean = false
  @Input() max_width: boolean = false
  @Input() startDateRequired: boolean = false
  @Input() endDateRequired: boolean = false

  @Output() onStartDate: EventEmitter<string> = new EventEmitter<string>()
  @Output() onEndDate: EventEmitter<string> = new EventEmitter<string>()

  @Input() startDate: FormControl = new FormControl()
  @Input() endDate: FormControl = new FormControl()

  minDate: Moment = moment();

  form: FormGroup = new FormGroup({
    startDate: this.startDate,
    endDate: this.endDate
  })

  constructor() { }

  ngOnInit(): void {
    this.emitDates(this.formatDate(this.startDate.value), this.formatDate(this.endDate.value))
  }

  formatDate(date: string): string {
    const formatDate = moment(date).format('YYYY-MM-DD')

    return formatDate
  }

  onStartDateChange($event: any): void {
    this.startDate.setValue($event.target.value)

    // if ($event.target.value > this.endDate.value) {
    //   this.endDate.setValue($event.target.value)
    //   this.emitDates(this.formatDate($event.target.value), this.formatDate($event.target.value))
    //   return
    // }

    this.onStartDate.emit(moment($event.target.value).format('MM/YY').toString())
  }

  onEndDateChange($event: any): void {
    if ($event.target.value < this.startDate.value) {
      this.endDate.setValue(this.startDate.value)
      this.emitDates(this.formatDate(this.startDate.value), this.formatDate(this.startDate.value))
      return
    }

    this.endDate.setValue($event.target.value)

    this.onEndDate.emit(this.formatDate($event.target.value))
  }

  emitDates(startDate: string, endDate: string): void {
    this.onStartDate.emit(startDate)
    this.onEndDate.emit(endDate)
  }

  setMonthAndYear(normalizedMonthAndYear: Moment, datepicker: MatDatepicker<Moment>) {
    const ctrlValue = this.startDate.value!;

    ctrlValue.month(normalizedMonthAndYear.month());
    ctrlValue.year(normalizedMonthAndYear.year());

    this.startDate.setValue(ctrlValue);

    datepicker.close();
  }
}
