<div class="container">
  <div class="img-loading">
    <!-- <img class="icon" src="../../../../../assets/svg/candado.svg" alt=""> -->
    <!-- <img class="gift" src="https://s3-alpha-sig.figma.com/img/d95d/a9e9/4ae424b7a86e298030c680b2fc86fe48?Expires=1690761600&Signature=PrLQUYAmZ3Qj-B5EjzeC3bk22MKispdLE8Yh41xSdKZBUNG7eLQYDHmhEh61QJiA5UM7VW387AzgVsltR5TkYrwaRYRzHmzB7a2eFB1XaWPj0Un3SsfDEsMEUusZDivU8fIG2-hpGstBskzl8Mi-nPhFsjz~gbFOSVX9SQtcZqcvIhUiVlVlY8apJO6Ca5I-IvDKb7CZaUoqZSp-hEqvbHzTdsKmYtGgfhtce99i517g0uP1~IyhS7QTJb4XXDChgwGJGd9GEdIrD04WoCgnTb04BKLG9SCqJ50Y7IPXDhbIEuigDG~mBnoiKhUNZ4XAMG9puTdfpieKejWFYNjZFw__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4" alt="Imagen de un spinner de carga"> -->
    <ui-custom-spinner [diameter]="'120'"></ui-custom-spinner>
  </div>
  <div class="info">
    <h2 class="message">Procesando tu pago</h2>
    <p class="sub-message">
      Por favor no cierres ni actualices esta ventana. Estamos procesando tu pago.
    </p>
  </div>
</div>
