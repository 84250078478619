import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dynamic-card-viewer',
  templateUrl: './dynamic-card-viewer.component.html',
  styleUrls: ['./dynamic-card-viewer.component.scss']
})
export class DynamicCardViewerComponent {
  @Input() set cardType(value: string | undefined) {
    this.selectedCard = this.cardLogo(value)
  }

  @Input() cardNumber: string = 'XXXX XXXX XXXX XXXX'
  @Input() expirationDate: string = 'MM/YY'
  @Input() cardName: string = 'NOMBRE Y APELLIDO'
  @Input() cvv: string = 'XXX'
  @Input() selectedCard: string | undefined = 'generic.svg'

  cardLogo(cardType: string | any): string {
    const data: { [key: string]: string } = {
      'VIS': 'VIS',
      'ECA': 'ECA',
      'AMX': 'AMX',
      'DIN': 'DIN',
      'generic.svg': 'generic.svg'
    };

    if (!data[cardType]) {
      return 'generic.svg'
    }

    if (!cardType) {
      return 'generic.svg'
    }

    return data[cardType];
  }
}
