<ui-input-label [required]="required" [label]="label" [color]="labelColor"></ui-input-label>
<mat-form-field
  class="select"
  [style]="{ 'width': max_width ? '100%' : customWidth }"
  [ngClass]="getClass()"
>
  <mat-select
    [placeholder]="placeholder"
    [ngClass]="getClass()"
    class="custom-input-heigth"
    [formControl]="value"
    (selectionChange)="onChange($event)"
    [required]="required"
    [multiple]="multiple"
  >
    <span *ngFor="let options of valueOptions">
      <mat-option [value]="options.value">{{ options.label }}</mat-option>
    </span>
  </mat-select>
</mat-form-field>
