import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-payment-expired-screen',
  templateUrl: './payment-expired-screen.component.html',
  styleUrls: ['./payment-expired-screen.component.scss']
})
export class PaymentExpiredScreenComponent {
  @Input() backUrl: string = ''

  back(): void {
    window.location.replace(this.backUrl)
  }
}
