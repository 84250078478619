import { Component, OnInit, Renderer2 } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ScriptService } from './script';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  isLoading: boolean = false

  constructor(
    private scriptService: ScriptService,
    private renderer: Renderer2,
  ) {
  }

  ngOnInit(): void {
    const pciUrl = this.scriptService.loadJsScript(this.renderer, environment.pci_proxy_url)
    const yunoUrl = this.scriptService.loadJsScript(this.renderer, environment.yuno_sdk)

    pciUrl.onload = () => {
      this.callback()
    }

    yunoUrl.onload = () => {
      this.callback()
    }
  }

  callback() {
    setTimeout(() => {
      this.isLoading = true
    }, 500);
  }
}
