import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-bad-error-screen',
  templateUrl: './bad-error-screen.component.html',
  styleUrls: ['./bad-error-screen.component.scss']
})
export class BadErrorScreenComponent {
  @Input() backUrl: string = ''
}
