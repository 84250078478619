<div class="container">
    <div class="img-loading">
      <img src="../../../../../assets/svg/progress.svg" alt="">
    </div>
    <div class="info">
      <h2 class="message">Solicitud</h2>
      <h2 class="message">en proceso</h2>
      <p class="sub-message">
        Estamos verificando que tu pago se haya realizado correctamente.<br>
         Si aún no lo has realizado, ingresa nuevamente al link de pago y finaliza el proceso.</p>
    </div>
   
  </div>
  